// @flow
import React from 'react';

import FAQ from '../FAQ';

const Help = () => (
    <FAQ />
);

export default Help;
